import * as React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import Icon from "./Icon";
import Link from "./Link";

export interface LanguagePickerProps
{
	lang: string;
	langs: string[];
	collapsed: boolean;
}


interface LanguagePickerState
{
	menuOpen: boolean
}


export default class LanguagePicker extends React.PureComponent<LanguagePickerProps, LanguagePickerState>
{

	constructor(props: LanguagePickerProps, context?: any)
	{
		super(props, context);

		this.state = {
			menuOpen: false,
		};
	}


	render()
	{
		const {langs, lang, collapsed}  = this.props;
		const {menuOpen} = this.state;

		return (
			<Dropdown
				className={"LanguagePicker" + (collapsed ? " LanguagePicker--collapsed" : "")}
				nav={true}
				isOpen={menuOpen}
				toggle={this.toggleDropdown.bind(this)}
			>
				<DropdownToggle caret nav={true} className="LanguagePicker__toggle">
					<Icon name="language" className="LanguagePicker__toggle-icon" />
					<span className="LanguagePicker__toggle-text">{langNames[lang]}</span>
				</DropdownToggle>
				<DropdownMenu right={true} className="shadow--1">
					{langs.map((lang) => (
						<Link
							key={lang}
							to={{lang}}
							forceReload={true}
							onClick={(e) => this.closeMenu()}
							className="dropdown-item"
						>
							{langNames[lang]}
						</Link>
					))}
				</DropdownMenu>
			</Dropdown>
		);
	}


	private toggleDropdown(): void
	{
		this.setState({menuOpen: !this.state.menuOpen});
	}


	private closeMenu(): void
	{
		this.setState({menuOpen: false});
	}

}


const langNames: {[code: string]: string} = {
	"sk": "Slovenčina",
	"pl": "Polski",
};
