import * as React from "react";
import AppMetadata from "../model/AppMetadata";
import Content from "./Content";
import Map from "./Map";
import Header from "./Header";
import MapData, {MapDataPointLiteral, MapDataSegmentLiteral} from "./../model/MapData";
import SideContent from "./SideContent";
import {object as ObjectPropType} from "prop-types";
import MainApp, {MainAppConfig} from "../apps/MainApp";
import {ViewDef} from "../views/View";
import GoogleMapsApiProvider from "../../browser/services/GoogleMapsApiProvider";
import MapDataProvider from "../../browser/services/MapDataProvider";
import MapSearch, {MapSearchResult} from "../../browser/services/MapSearch";



export interface MainTemplateParams
{
	status: number;
	lang: string;
	title: string;
	description: string;
	robots: string;
	canonicalUrl: string | undefined;
	alternateLangUrls: {[lang: string]: string};

	logoLink?: string | ViewDef;

	mainContent?: JSX.Element | string | null | undefined;
	fullMap: boolean;
	showSide: boolean;
	sideFullscreen?: boolean;
	sideCover?: JSX.Element;
	sideTitle?: string;
	sideHeaderControls?: JSX.Element | undefined;
	sideContent?: JSX.Element;
	mapActivePoint?: number | undefined;
	mapActivePath?: number | undefined;
}



interface MainTemplateProps
{
	config: MainAppConfig;
	templateParams: MainTemplateParams;

	metadataRenderer: (metadata: AppMetadata) => void;
	googleApiProvider: GoogleMapsApiProvider | undefined;
	mapDataProvider: MapDataProvider | undefined;
	mapSearch?: MapSearch | undefined;
}



interface MainTemplateState
{
	googleApiReady: boolean;
	mapData: MapData | undefined;
	isPhone: boolean | undefined;
}



export default class MainTemplate extends React.PureComponent<MainTemplateProps, MainTemplateState>
{

	private refMap: Map | null | undefined;
	private refSideContent: SideContent | null | undefined;
	private refPhoneDetector: HTMLDivElement | null | undefined;


	context: { app: MainApp };

	static contextTypes = {
		app: ObjectPropType,
	};


	constructor(props: MainTemplateProps, context?: any)
	{
		super(props, context);

		this.state = {
			googleApiReady: false,
			mapData: undefined,
			isPhone: undefined,
		};
	}


	componentDidMount()
	{
		const { googleApiProvider, mapDataProvider } = this.props;

		googleApiProvider && googleApiProvider.onLoad(() => {
			this.setState({ googleApiReady: true });
		});

		mapDataProvider && mapDataProvider.fetchMapData().then((mapData: MapData) => {
			this.setState({ mapData });
		});

		window.setInterval(() => this.evaluateDeviceSize(), 100);
		this.evaluateDeviceSize();
	}


	componentWillUnmount()
	{
		throw new Error("MainApp component will unmount");
	}


	render()
	{
		const p = this.props.templateParams;
		const s = this.state;

		const logoLink = p.logoLink || (p.showSide ? "map": "home");

		this.props.metadataRenderer({
			status: p.status,
			lang: p.lang,
			canonicalUrl: '',
			title: p.title,
			description: p.description,
			robots: p.robots,
		});

		return (
			<React.Fragment>
				<Header
					collapsed={p.fullMap}
					lang={p.lang}
					langs={this.props.config.langs}
					logoLink={logoLink}
					mapSearch={this.props.mapSearch}
					onSearchSelect={this.handleSearchSelect}
				/>
				<Map
					ref={this.setMapRef}
					isPhone={s.isPhone}
					full={p.fullMap}
					sideCover={p.showSide ? (p.sideFullscreen ? "big" : "small") : "none"}
					googleApiReady={s.googleApiReady}
					mapData={s.mapData}
					activePointId={p.mapActivePoint}
					activePathId={p.mapActivePath}
					onClick={this.handleMapClick}
					onPlaceClick={this.handlePlaceClick}
					onSegmentClick={this.handleSegmentClick}
					lang={p.lang}
				/>
				<SideContent
					ref={this.setSideContentRef}
					show={p.showSide}
					fullscreen={!!p.sideFullscreen}
					cover={p.sideCover}
					title={p.sideTitle}
					headerControls={p.sideHeaderControls}
					content={p.sideContent}
				/>
				<Content hidden={p.fullMap}>
					{p.mainContent}
				</Content>
				<div className="d-none d-sm-block" ref={this.setPhoneDetectorRef}/>
			</React.Fragment>
		);
	}


	private setMapRef = (ref: Map) => this.refMap = ref;
	private setSideContentRef = (ref: SideContent) => this.refSideContent = ref;
	private setPhoneDetectorRef = (ref: HTMLDivElement) => this.refPhoneDetector = ref;


	private handleSearchSelect = (result: MapSearchResult) =>
	{
		this.context.app.go(result.view);

		const mapData = this.state.mapData;
		if (!mapData) {
			return;
		}

		if (result.type === 'place') {
			this.refMap?.panZoomToPlace(result.id);
		}

		if (result.type === 'path') {
			this.refMap?.panZoomToPath(result.id);
		}
	}


	private evaluateDeviceSize()
	{
		if (!this.refPhoneDetector) {
			return;
		}

		const el = this.refPhoneDetector as any;
		const display = el.currentStyle ? el.currentStyle.display : (getComputedStyle as any)(el, null).display;
		const isPhone = (display !== "block");

		if (this.state.isPhone !== isPhone) {
			this.setState({isPhone});
		}
	}


	private handleMapClick = (): void =>
	{
		// this.setState({ activePoint: undefined });
	}


	private handlePlaceClick = (point: MapDataPointLiteral): void =>
	{
		this.context.app.go({
			controller: "detail",
			entity: { type: "place", id: point.id },
		});
	}


	private handleSegmentClick = (segment: MapDataSegmentLiteral): void =>
	{
		const currentActivePathId = this.props.templateParams.mapActivePath;
		const currentActivePathIdIndex = currentActivePathId !== undefined ? segment.pathIds.indexOf(currentActivePathId) : -1;
		const nextPathIdIndex = (currentActivePathIdIndex + 1) % segment.pathIds.length;
		const pathId = segment.pathIds[nextPathIdIndex];

		this.context.app.go({
			controller: "detail",
			entity: { type: "path", id: pathId },
		});
	}
}
