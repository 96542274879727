import * as React from "react";
import LanguagePicker from "./LanguagePicker";
import Logo from "./Logo";
import Search from "./Search";
import Link from "./Link";
import {ViewDef} from "../views/View";
import MapSearch, {MapSearchResult} from "../../browser/services/MapSearch";



interface HeaderProps
{
	lang: string;
	langs: string[];
	collapsed: boolean;
	logoLink: ViewDef | string;
	mapSearch?: MapSearch;
	onSearchSelect: (result: MapSearchResult) => void;
}



export default class Header extends React.PureComponent<HeaderProps>
{

	render()
	{
		const {langs, lang, collapsed, logoLink, mapSearch} = this.props;

		return (
			<div className={'navbar navbar-light bg-light Header' + (collapsed ? ' Header--collapsed' : '')}>
				<div className={"container" + (collapsed ? " pl-0 pr-0" : "")}>

					<div className="Header__eu">
						<img src="/res/img/logo-interreg.svg" alt="" />
						<img src="/res/img/logo-mesto-vysoke-tatry.svg" alt="" />
					</div>

					<Link to={logoLink}>
						<Logo />
					</Link>

					<Search
						lang={lang}
						collapsed={collapsed}
						mapSearch={mapSearch}
						onSelect={this.props.onSearchSelect}
					/>

					<ul className="navbar-nav">
						<LanguagePicker
							lang={lang}
							langs={langs}
							collapsed={collapsed}
						/>
					</ul>
				</div>
			</div>
		);
	}

}
