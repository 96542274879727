import withGoogleMap from "react-google-maps/lib/withGoogleMap";
import {GoogleMap, GoogleMapProps} from "react-google-maps";
import * as React from "react";



interface MapRefProps
{
	mapRef?: (ref: GoogleMap) => void;
}



interface GoogleMapWithGoogleMapProps extends MapRefProps
{
	mapProps: GoogleMapProps;
	children: any;
}



interface MapComponentProps extends GoogleMapProps, MapRefProps
{

}



const GoogleMapWithGoogleMap = withGoogleMap((props: GoogleMapWithGoogleMapProps) => {
	const {children, mapRef, mapProps} = props;
	return (
		<GoogleMap ref={mapRef} {...mapProps}>
			{children}
		</GoogleMap>
	);
});



export default class MapComponent extends React.PureComponent<MapComponentProps>
{

	render()
	{
		const {children, mapRef, ...props} = this.props;
		return (
			<GoogleMapWithGoogleMap
				containerElement={<div style={{height: "100%"}} />}
				mapElement={<div style={{height: "100%"}} />}
				mapRef={mapRef}
				mapProps={props}
			>
				{children}
			</GoogleMapWithGoogleMap>
		);
	}

}
