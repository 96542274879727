import ViewDataResolver from "./ViewDataResolver";
import View, {ViewController} from "../views/View";



export default class ViewResolver
{

	private viewController: ViewController<View>;
	private viewDataResolver: ViewDataResolver;


	constructor(viewController: ViewController<View>, viewDataResolver: ViewDataResolver)
	{
		this.viewDataResolver = viewDataResolver;
		this.viewController = viewController;
	}


	async resolve(view: View): Promise<View>
	{
		if (this.viewController.isResolved(view)) {
			return view;
		}

		const resolvingKeysByFields = this.viewController.getViewDataResolvingKeys(view);
		const fields = Object.keys(resolvingKeysByFields);
		const resolvingKeys = fields.map((field: string) => resolvingKeysByFields[field]);
		const resolvedData = await this.viewDataResolver.resolve(resolvingKeys);
		const resolvedView = { ...view};

		for (const field of fields) {
			const fieldResolvingKey = resolvingKeysByFields[field];
			const fieldData = resolvedData[fieldResolvingKey];

			resolvedView[field] = fieldData;
		}

		return resolvedView;
	}


	resolveFromCache(view: View): View | undefined
	{
		if (this.viewController.isResolved(view)) {
			return view;
		}

		const resolvingKeysByFields = this.viewController.getViewDataResolvingKeys(view);
		const fields = Object.keys(resolvingKeysByFields);
		const resolvingKeys = fields.map((field: string) => resolvingKeysByFields[field]);
		const resolvedData = this.viewDataResolver.resolveFromCache(resolvingKeys);
		if (resolvedData === undefined) {
			return undefined;
		}

		const resolvedView = { ...view};

		for (const field of fields) {
			const fieldResolvingKey = resolvingKeysByFields[field];
			const fieldData = resolvedData[fieldResolvingKey];
			resolvedView[field] = fieldData;
		}

		return resolvedView;
	}


	addCache(view: View): void
	{
		const resolvingKeysByFields = this.viewController.getViewDataResolvingKeys(view);
		const fields = Object.keys(resolvingKeysByFields);


		const data = {};
		for (const field of fields) {
			const fieldData = view[field];
			const fieldKey = resolvingKeysByFields[field];
			data[fieldKey] = fieldData;
		}

		this.viewDataResolver.addCache(data);
	}

}
