import BaseView, {BaseViewController} from "./BaseView";
import {MainTemplateParams} from "../components/MainTemplate";
import View, {ViewDef} from "./View";
import HomeView from "./HomeView";



export default interface MapView extends BaseView
{
	controller: "map";
}



export class MapViewController extends BaseViewController<MapView>
{

	sanitize(view: ViewDef, currentView: View): void
	{
		super.sanitize(view, currentView);

		// nothing more
	}


	equals(view: MapView, view2: View): boolean
	{
		return super.equals(view, view2) && MapViewController.isMapView(view2);
	}


	getViewDataResolvingKeys(view: MapView): any
	{
		return {
		};
	}


	getTitle(view: MapView): string
	{
		return this.getBaseTitle(view, messages[view.lang].title);
	}


	getUrl(view: MapView): string
	{
		return super.getUrl(view) + "mapa"; // todo: by lang
	}


	render(view: MapView): MainTemplateParams
	{
		const msgs = messages[view.lang];

		return {
			...super.renderBase(view),
			status: 200,
			title: this.getTitle(view),
			description: msgs.description,
			robots: "index, follow",
			canonicalUrl: undefined,
			alternateLangUrls: {},

			fullMap: true,
			showSide: false,
		};
	}


	static isMapView(view: View): view is MapView
	{
		return (view.controller === "map");
	}

}



const messages: {[lang: string]: {
	title: string;
	description: string;
}} = {
	sk: {
		title: "Mapa",
		description: "",
	},
	pl: {
		title: "Mapa",
		description: "",
	}
};
