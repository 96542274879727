import * as React from "react";
import Icon from "./Icon";
import MapSearch, { MapSearchResult } from "../../browser/services/MapSearch";
const Autocomplete = require("react-autocomplete");


const SEARCH_DELAY = 300;


interface SearchProps
{
	lang: string;
	collapsed: boolean;
	mapSearch?: MapSearch;
	onSelect: (result: MapSearchResult) => void;
}


interface SearchState
{
	active: boolean;
	input: string,
	results: MapSearchResult[],
}



export default class Search extends React.PureComponent<SearchProps, SearchState>
{

	private refInput: HTMLInputElement | undefined;
	private delayTimeout: number | undefined;


	state = {
		active: false,
		input: "",
		results: [],
	};


	componentDidMount()
	{
		this.fetchResults("");
	}


	render()
	{
		const {collapsed, lang} = this.props;
		const {active, results} = this.state;
		const msgs = messages[lang];

		return (
			<div className={"Search" + (collapsed ? " Search--collapsed" : "") + (active ? " Search--active" : "")}>
				<Autocomplete
					ref={(ref: HTMLInputElement) => this.refInput = ref}
					inputProps={{
						placeholder: (collapsed ? msgs.searchOn : msgs.search),
						className: "Search__input",
						onBlur: () => this.setState({active: false}),
					}}
					wrapperProps={{className: "Search__wrapper"}}
					wrapperStyle={{}}
					value={this.state.input}
					open={this.state.active}
					items={results}
					getItemValue={(item: MapSearchResult) => item.title}
					renderMenu={(items: MapSearchResult[], value: any, style: any) => this.renderMenu(items, value, style)}
					renderItem={(item: MapSearchResult, isHighlighted: boolean) => this.renderItem(item, isHighlighted)}
					onMenuVisibilityChange={(isOpen: boolean) => this.handleMenuVisibilityChange(isOpen)}
					onChange={(e: any, value: string) => this.handleInputChange(value)}
					onSelect={(value: string, item: MapSearchResult) => this.handleSelect(value, item)}
				/>
				<span className="Search__button" onClick={() => this.handleIconClick()}>
					<Icon name="search" className="Search__icon" />
				</span>
			</div>
		);
	}


	private renderMenu(items: MapSearchResult[], value: string, style: any)
	{
		return (<div className="Search__items shadow--1" children={items} />);
	}


	private renderItem(item: MapSearchResult, isHighlighted: boolean): JSX.Element
	{
		return (
			<div key={item.type + '-' + item.id} className={"Search__item" + (isHighlighted ? ' Search__item--highlighted' : '')}>
				{item.title}
			</div>
		);
	}


	private handleMenuVisibilityChange(isOpen: boolean)
	{
		this.setState({ active: isOpen });
	}


	private handleInputChange(input: string): void
	{
		this.setState({input});

		if (this.delayTimeout !== undefined) {
			window.clearTimeout(this.delayTimeout);
		}
		this.delayTimeout = window.setTimeout(() => this.fetchResults(input), SEARCH_DELAY);
	}


	private handleSelect(input: string, item: MapSearchResult): void
	{
		this.setState({ input: "", active: false });
		this.fetchResults("");
		if (this.refInput) {
			this.refInput.blur();
		}

		this.props.onSelect(item);
	}


	private handleIconClick(): void
	{
		this.setState({ active: true });
		this.refInput && this.refInput.focus();
	}


	private async fetchResults(searchTerm: string): Promise<void>
	{
		const service = this.props.mapSearch;
		if (!service) {
			return;
		}

		let results;

		try {
			results = await service.fetchResults(searchTerm, this.props.lang);
		} catch (er) {
			return;
		}

		if (this.state.input === searchTerm) {
			this.setState({ results });
		}
	}

}



const messages: {[lang: string]: {
	search: string;
	searchOn: string;
}} = {
	sk: {
		search: "Hľadať...",
		searchOn: "Hľadať na TatryVirtualne.sk...",
	},
	pl: {
		search: "Wyszukaj...",
		searchOn: "Wyszukaj w TatryVirtualne.sk...",
	}
};
