import HttpApi from "./HttpApi";
import {ViewDef} from "../../common/views/View";



export default class MapSearch
{

	private api: HttpApi;

	private cache: {[input: string]: MapSearchResult[]} = {};


	constructor(httpApi: HttpApi)
	{
		this.api = httpApi;
	}


	public async fetchResults(searchTerm: string, lang: string): Promise<MapSearchResult[]>
	{
		const cacheKey = lang + ":" + searchTerm;
		if (this.cache[cacheKey] !== undefined) {
			return this.cache[cacheKey];
		}
		const result = await this.api.get("search", {lang, term: searchTerm}) as MapSearchResult[];
		this.cache[cacheKey] = result;
		return result;
	}

}



export interface MapSearchResult
{
	type: 'place' | 'path';
	id: number;
	title: string;
	view: ViewDef;
}
