import {Component} from "react";
import AppMetadata from "../model/AppMetadata";



export type MetadataRenderer = (metadata: AppMetadata) => void;



export interface BaseAppProps
{
	metadataRenderer: MetadataRenderer
}



export interface BaseAppClass<P extends BaseAppProps, S>
{
	new(): BaseApp<P, S>;
}



export default class BaseApp<P extends BaseAppProps, S> extends Component<P, S>
{

}
