export default class HttpApi
{

	private baseApiUrl: string;


	constructor(baseApiUrl: string = "/api/")
	{
		this.baseApiUrl = baseApiUrl;
	}


	public async get(path: string, query: {[key: string]: string} = {}): Promise<Object>
	{
		const queryStr = Object.keys(query)
			.map((k => encodeURIComponent(k) + '=' + encodeURIComponent(query[k])))
			.join('&');
		const url = this.baseApiUrl + path + (queryStr === '' ? '' : '?' + queryStr);
		const response = await fetch(url, {
			credentials: 'same-origin',
		});

		if (response.status < 200 || response.status >= 300) {
			throw new Error(response.toString());
		}

		return response.json();
	}


	public async post(url: string, data: Object): Promise<Object>
	{
		const response = await fetch(this.baseApiUrl + url, {
			method: "POST",
			headers: {
				"content-type": "application/json",
			},
			credentials: 'same-origin',
			body: JSON.stringify(data),
		});

		return response.json();
	}

}
