// import "pannellum/build/pannellum.css";
// declare module 'pannellum/build/pannellum.js';

// const pannellum: Pannellum = require("pannellum/build/pannellum.js");


export interface PannellumGeneralConfig
{
	title?: string | null;
	author?: string | null;
	strings?: any;
	basePath?: string;
	autoLoad?: boolean;
	autoRotate?: number;
	autoRotateInactivityDelay?: number;
	autoRotateStopDelay?: number;
	fallback?: string;
	orientationOnByDefault?: boolean;
	showZoomCtrl?: boolean;
	keyboardZoom?: boolean;
	mouseZoom?: boolean | "fullscreenonly";
	draggable?: boolean;
	disableKeyboardCtrl?: boolean;
	showFullscreenCtrl?: boolean;
	showControls?: boolean;
	yaw?: number;
	pitch?: number;
	hfov?: number;
	minYaw?: number;
	maxYaw?: number;
	minPitch?: number;
	maxPitch?: number;
	minHfov?: number;
	maxHfov?: number;
	compass?: boolean;
	northOffset?: number;
	preview?: string;
	previewTitle?: string;
	previewAuthor?: string;
	horizonPitch?: number;
	horizonRoll?: number;
	animationTimingFunction?: (p: number) => number;
	escapeHTML?: boolean;
	crossOrigin?: "anonymous" | "use-credentials"
	hotSpots?: ({
		pitch: number;
		yaw: number;
		type: "scene" | "info";
		text?: string;
		URL?: string;
		sceneId?: string;
		targetPitch?: number | "same";
		targetYaw?: number | "same" | "sameAzimuth";
		targetHfov?: number;
		id: any;
		cssClass?: string;
		createTooltipFunc?: () => void;
		clickHandlerFunc?: () => void;
		hotSpotDebug?: boolean;
		sceneFadeDuration?: number;
	}[]);
}


interface PannelumConfigEquirectangular extends PannellumGeneralConfig
{
	type: "equirectangular";
	panorama: string;
	haov: number;
	vaov: number;
	vOffset: number;
	ignoreGPanoXMP: boolean;
	backgroundColor: number[];
	dynamic: boolean;
}



interface PannelumConfigCubemap extends PannellumGeneralConfig
{
	type: "cubemap";
	cubeMap: string[];
}



interface PannelumConfigMultires extends PannellumGeneralConfig
{
	type: "multires";
	multiRes: {
		path: string;
		fallbackPath: string;
		extension: string;
		tileResolution: number;
		maxLevel: number;
		cubeResolution: number;
	}
}


export type PannellumResolvedConfig = PannelumConfigEquirectangular | PannelumConfigCubemap | PannelumConfigMultires;
export type PannellumUnResolvedConfig = { config: string } & Partial<PannellumResolvedConfig>;
export type PannellumConfig = PannellumResolvedConfig | PannellumUnResolvedConfig;



export interface PannellumViewer
{

	/** Returns the pitch in degrees of the center of the view. */
	getPitch(): number;

	/** Returns the horizontal field of view in degrees. */
	getHfov(): number;

	/** Returns the yaw in degress of the center of the view. */
	getYaw(): number;

	/** This method should be called if the viewer’s container is resized. */
	resize(): void;

	/** Destructor */
	destroy(): void;

	/** Toggle fullscreen. */
	toggleFullscreen(): PannellumViewer;
}



export interface PanoramaView
{
	yaw: number;
	pitch: number;
	hfov: number;
}



export interface Pannellum
{
	viewer: (container: HTMLElement | string, initialConfig: PannellumResolvedConfig) => PannellumViewer
}



export function isConfigResolved(config: PannellumConfig): config is PannellumResolvedConfig
{
	return (config as PannellumUnResolvedConfig).config === undefined;
}



export async function resolveConfig(config: PannellumConfig): Promise<PannellumResolvedConfig>
{
	if (isConfigResolved(config)) {
		return config;
	}

	const { config:configUrl, ...configWithoutUrl } = config;
	const response = await fetch(configUrl);
	const remoteConfig = await response.json();

	if (remoteConfig.basePath === undefined) {
		remoteConfig.basePath = configUrl.substring(0, configUrl.lastIndexOf('/') + 1);
	}

	const resolvedConfig: PannellumResolvedConfig = {...remoteConfig, ...configWithoutUrl};
	for (const key of ["title", "author"]) {
		if (resolvedConfig[key] === null) {
			delete resolvedConfig[key];
		}
	}

	return resolvedConfig;
}
