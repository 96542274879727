export default class GoogleMapsApiProvider
{

	private callbacks: (() => void)[] = [];


	public isReady(): boolean
	{
		const google = (window as any).google;
		return !!(google && google.maps);
	}


	public onLoad(callback: () => void): void
	{
		if (this.isReady()) {
			callback();
			return;
		}
		this.callbacks.push(callback);
	}


	// public async get(): Promise<any>
	// {
	// 	return new Promise<any>((yeah) => this.onLoad(yeah));
	// }


	private triggerLoaded(): void
	{
		for (const callback of this.callbacks) {
			callback();
		}
		this.callbacks.splice(0, this.callbacks.length);
	}


	public register(onLoadFunction: string = "googleMapsApiLoaded"): GoogleMapsApiProvider
	{
		if (!this.isReady()) {
			const win = (window as any);
			const originalOnLoad = win[onLoadFunction];
			win[onLoadFunction] = () => {
				if (originalOnLoad) {
					originalOnLoad.call(undefined);
				}
				this.triggerLoaded();
			};
		}

		return this;
	}

}
