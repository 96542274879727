import * as React from "react";
import {object as ObjectPropType} from "prop-types";
import AppTemplate from "../components/MainTemplate";
import View, {ViewDef} from "../views/View";
import ViewResolver from "../model/ViewResolver";
import RootComponent, {BaseAppProps} from "./BaseApp";
import CompositeViewController from "../views/CompositeViewController";
import MapDataProvidder from "../../browser/services/MapDataProvider";
import GoogleApiProvider from "../../browser/services/GoogleMapsApiProvider";
import MapSearch from "../../browser/services/MapSearch";



export interface MainAppConfig
{
	langs: string[];
	baseUrlPanorama: string;
	appleItunesAppUrl: string;
	googlePlayAppId: string;
}



export interface MainAppParams
{
	config: MainAppConfig;
	initialView: View;
}



export interface MainAppServices
{
	viewController: CompositeViewController;
	viewResolver?: ViewResolver;
	googleApiProvider?: GoogleApiProvider;
	mapDataProvider?: MapDataProvidder;
	mapSearch?: MapSearch;
}



interface MainAppProps extends BaseAppProps, MainAppParams, MainAppServices
{
}



interface AppState
{
	view: View;
}



export default class MainApp extends RootComponent<MainAppProps, AppState>
{

	constructor(props: MainAppProps, context?: any)
	{
		super(props, context);

		this.state = {
			view: props.initialView,
		};
	}


	componentDidMount()
	{
		this.initHistory();
	}


	getCurrentView(): View
	{
		return this.state.view;
	}


	setView(view: View, callback?: () => void)
	{
		this.setState({view}, callback);
	}


	initHistory(): void
	{
		const { viewController, viewResolver } = this.props;

		const view = this.getCurrentView();
		const href = viewController.getUrl(view);
		const title = viewController.getTitle(view);

		if (viewResolver) {
			viewResolver.addCache(view);
		}

		window.history.replaceState({view}, title, href);
		window.onpopstate = (evt: PopStateEvent) => {this.handleHistoryPopState(evt)};
	}


	private handleHistoryPopState(event: PopStateEvent)
	{
		const { viewController } = this.props;
		const view: View = event.state.view;
		this.setView(view);
		const href = viewController.getUrl(view);
		this.trackPageview(href);
	}


	async go(target: string | ViewDef): Promise<void>
	{
		const { viewController, viewResolver } = this.props;

		const sanitizedView = this.sanitizeView(target);
		let resolvedViewFromCache = viewResolver && viewResolver.resolveFromCache(sanitizedView);
		const view = resolvedViewFromCache ? resolvedViewFromCache : sanitizedView;

		this.setView(view);

		if (resolvedViewFromCache) {
			this.pushAndTrackView(view);
			return;
		}

		if (!viewResolver) {
			return;
		}

		const resolvedView = await viewResolver.resolve(view);

		if (viewController.equals(resolvedView, this.getCurrentView())) {
			this.setView(resolvedView);
			this.pushAndTrackView(resolvedView);
		}
	}


	private pushAndTrackView(view: View): void
	{
		const { viewController } = this.props;
		const title = viewController.getTitle(view);
		const href = viewController.getUrl(view);
		window.history.pushState({view: view}, title, href);
		this.trackPageview(href);
	}


	private trackPageview(href: string)
	{
		(window as any).ga("send", "pageview", href);
	}


	createHref(view: string | ViewDef): string | undefined
	{
		const { viewController } = this.props;

		const sanitizedView = this.sanitizeView(view);
		return viewController.getUrl(sanitizedView);
	}


	private sanitizeView(view: string | ViewDef): View
	{
		const { viewController } = this.props;

		let sanitizedView: ViewDef;

		if (typeof view === "string") {
			const [controller /*, extraArgs */] = view.split(" ", 2);
			sanitizedView = {controller};
			// todo: extraArgs
		} else {
			sanitizedView = { ...view };
		}

		const currentView = this.getCurrentView();
		viewController.sanitize(sanitizedView, currentView);
		return sanitizedView as View;
	}


	render()
	{
		const { viewController } = this.props;
		const templateParams = viewController.render(this.state.view);
		// const canonicalUrl = viewController.getUrl(this.state.view);

		return (
			<AppTemplate
				config={this.props.config}
				templateParams={templateParams}
				metadataRenderer={this.props.metadataRenderer}
				mapDataProvider={this.props.mapDataProvider}
				googleApiProvider={this.props.googleApiProvider}
				mapSearch={this.props.mapSearch}
			/>
		);
	}


	getChildContext(): { app: MainApp }
	{
		return { app: this };
	}


	static childContextTypes = {
		app: ObjectPropType,
	};

}
