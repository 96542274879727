import * as React from "react";



export default class Logo extends React.PureComponent
{

	render()
	{
		return (
			<h1 className="navbar-brand Logo">
				<div className="Logo__logo" />
				<span className="Logo__caption">TatryVirtualne.sk</span>
			</h1>
		);
	}

}
