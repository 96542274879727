import AppMetadata from "../../common/model/AppMetadata";



export default function metadataRenderer(metadata: AppMetadata)
{

	document.title = metadata.title;

	// todo

};
