import {ViewController, ViewDef} from "./View";
import {MainTemplateParams} from "../components/MainTemplate";
import View from "./View";



export default class CompositeViewController extends ViewController<View>
{

	private controllers: {[controllerName: string]: ViewController<View>} = {};


	constructor(controllers: {[controllerName: string]: ViewController<View>})
	{
		super();

		this.controllers = controllers;
	}


	private getController(view: View): ViewController<View>
	{
		const controller = this.controllers[view.controller];
		if (!controller) {
			throw new Error("Controller does not exist: \"" + view.controller + "\"");
		}
		return controller;
	}


	sanitize(view: Partial<View> | ViewDef, currentView: View): void
	{
		super.sanitize(view, currentView);
		this.getController(view as View).sanitize(view, currentView);
	}


	equals(view: View, view2: View): boolean
	{
		return this.getController(view).equals(view, view2);
	}


	getViewDataResolvingKeys(view: View): { [p: string]: string }
	{
		return this.getController(view).getViewDataResolvingKeys(view);
	}


	getTitle(view: View): string
	{
		return this.getController(view).getTitle(view);
	}


	getUrl(view: View): string
	{
		return this.getController(view).getUrl(view);
	}


	render(view: View): MainTemplateParams
	{
		return this.getController(view).render(view);
	}

}
