import BaseView, {BaseViewController} from "./BaseView";
import {MainTemplateParams} from "../components/MainTemplate";
import View, {ViewDef} from "./View";
import * as React from "react";
import {Key} from "react";
import Link from "../components/Link";



export default interface HomeView extends BaseView
{
	controller: "home";

	data?: HomeViewData

}


export interface HomeViewData
{
	featuredPlaces: FeaturedPlace[];
	featuredPaths: FeaturedPath[];
}



export interface FeaturedPlace
{
	id: number;
	slug: string;
	name: string;
	description: string;
	panorama: string;
}

export interface FeaturedPath
{
	id: number;
	slug: string;
	name: string;
	description: string;
	panorama: string;
}


export class HomeViewController extends BaseViewController<HomeView>
{

	private panoramaBaseUrl: string;
	private appleItunesAppUrl: string;
	private googlePlayAppId: string;


	constructor(panoramaBaseUrl: string, appleItunesAppUrl: string, googlePlayAppId: string)
	{
		super();

		this.panoramaBaseUrl = panoramaBaseUrl;
		this.appleItunesAppUrl = appleItunesAppUrl;
		this.googlePlayAppId = googlePlayAppId;
	}


	sanitize(view: ViewDef, currentView: View): void
	{
		super.sanitize(view, currentView);

		// nothing more
	}


	equals(view: BaseView, view2: View): boolean
	{
		return super.equals(view, view2) && HomeViewController.isHomeView(view2);
	}


	getViewDataResolvingKeys(view: HomeView): any
	{
		return {
			data: "home:" + view.lang,
		}
	}


	getTitle(view: HomeView): string
	{
		return this.getBaseTitle(view);
	}


	getUrl(view: HomeView): string
	{
		return super.getUrl(view) + "";
	}


	render(view: HomeView): MainTemplateParams
	{
		const msgs = messages[view.lang];
		return {
			...super.renderBase(view),
			status: 200,

			title: this.getTitle(view),
			description: msgs.description,
			robots: "index, follow",
			canonicalUrl: undefined,
			alternateLangUrls: {},

			mainContent: this.renderMain(view),
			fullMap: false,
			showSide: false,
		};
	}


	renderMain(view: HomeView): JSX.Element
	{
		return (
			<React.Fragment>
				{this.renderFeaturedPaths(view)}
				{this.renderFeaturedPlaces(view)}
				{this.renderAbout(view)}
				{this.renderFooter(view)}
			</React.Fragment>
		);
	}


	private renderFeaturedPlaces(view: HomeView): JSX.Element | undefined
	{
		let places: (FeaturedPlace|undefined)[];
		if (view.data) {
			places = view.data.featuredPlaces;
		} else {
			places = [undefined, undefined, undefined];
		}

		const msgs = messages[view.lang];

		return (
			<div className="Content__featured">
				<div className="container">
					<h2 className="pt-4 pb-4">{msgs.featuredPlaces}</h2>
					<div className="row">
						{places.map((place, index) => this.renderFeaturedPlace(index, place))}
					</div>
				</div>
			</div>
		);
	}


	private renderFeaturedPaths(view: HomeView): JSX.Element | undefined
	{
		const cols = 3;
		const paths = view.data?.featuredPaths ?? [];
		const rows = Math.max(1, Math.floor(paths.length / cols));
		const items = [...paths, ...Array(rows * cols - paths.length)];

		const msgs = messages[view.lang];

		return (
			<div className="Content__featured">
				<div className="container">
					<h2 className="pt-4 pb-4">{msgs.featuredPaths}</h2>
					<div className="row">
						{items.map((path, index) => this.renderFeaturedPath(index, path))}
					</div>
				</div>
			</div>
		);
	}


	private renderFeaturedPlace(key: Key, place: FeaturedPlace | undefined): JSX.Element
	{
		const placeLink = place && {
			controller: "detail",
			entity: { type: "place", id: place.id },
			slug: place.slug,
		};

		return (
			<Link key={key} className="PanoramaPreview d-block col col-6 col-md-4 pb-2" to={placeLink}>
				<div className="embed-responsive embed-responsive-16by9">
					<div
						className="PanoramaPreview__panorama embed-responsive-item bg-dark"
						style={place ? {backgroundImage: "url(" + this.panoramaBaseUrl + place.panorama + "/preview.jpg" + ")"} : {}}
					/>
				</div>
				<h3 className="PanoramaPreview__title h5 mt-2 mb-1">{place ? (place.name) : <span className="">...</span>}</h3>
				<p className="PanoramaPreview__description">
					{place ? place.description : (<span className="">......</span>)}
				</p>
			</Link>
		);
	}


	private renderFeaturedPath(key: Key, path: FeaturedPath | undefined): JSX.Element
	{
		const pathLink = path && {
			controller: "detail",
			entity: { type: "path", id: path.id },
			slug: path.slug,
		};

		return (
			<Link key={key} className="PanoramaPreview d-block col col-6 col-md-4 pb-2" to={pathLink}>
				<div className="embed-responsive embed-responsive-16by9">
					<div
						className="PanoramaPreview__panorama embed-responsive-item bg-dark"
						style={path ? {backgroundImage: "url(" + this.panoramaBaseUrl + path.panorama + "/preview.jpg" + ")"} : {}}
					/>
				</div>
				<h3 className="PanoramaPreview__title h5 mt-2 mb-1">{path ? (path.name) : <span className="">...</span>}</h3>
				<p className="PanoramaPreview__description">
					{path ? path.description : (<span className="">......</span>)}
				</p>
			</Link>
		);
	}


	private renderAbout(view: HomeView): JSX.Element
	{
		const { googlePlayAppId, appleItunesAppUrl } = this;
		const msgs = messages[view.lang] || messages["sk"];

		return (
			<div className="Content__about">
				<div className="container">
					{(googlePlayAppId || appleItunesAppUrl) && (
						<React.Fragment>
							<h2 className="pt-4 pb-4">{msgs.download}</h2>

							<div className="row">
								{googlePlayAppId && (<a className="col col-5 col-sm-4 col-md-3 col-lg-2" href={"https://play.google.com/store/apps/details?id=" + googlePlayAppId}>
									<img
										alt="Get it on Google Play"
										src="/res/download-google-play.svg"
										className="img-fluid"
									/>
								</a>)}

								{appleItunesAppUrl && (<a className="col col-5 col-sm-4 col-md-3 col-lg-2" href={appleItunesAppUrl}>
									<img
										alt="Download on app store"
										src="/res/download-app-store.svg"
										className="img-fluid"
									/>
								</a>)}
							</div>
						</React.Fragment>
					)}

					<div className="row">
						<div className="col-md-6">
							{msgs.about}
						</div>
						<div className="col-md-6 text-center">
							<img src="/res/img/logo-interreg.svg" alt="" className="img-fluid" style={{maxWidth: 400}} />
							<div className="d-flex flex-row justify-content-center">
								<img src="/res/img/logo-mesto-vysoke-tatry.svg" alt="" style={{ width: 110, margin: "15px"}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}


	private renderFooter(view: HomeView): JSX.Element
	{
		return (
			<div className="Content__footer">
				<div className="container">
					<div className="row">
						<p className="col col-12 col-sm-6">
							&copy; Mesto Vysoké Tatry
						</p>
						<p className="col col-12 col-sm-6 text-sm-right">
							<a href="https://www.digi-media.sk/">Vyrobila  digimedia</a>
						</p>
					</div>
				</div>
			</div>
		);
	}


	static isHomeView(view: View): view is HomeView
	{
		return (view.controller === "home");
	}

}


const messages: {[lang: string]: {
	description: string;
	featuredPlaces: string;
	featuredPaths: string;
	download: string;
	about: JSX.Element;
}} = {
	sk: {
		description: "",
		featuredPlaces: "Vybrané miesta",
		featuredPaths: "Vybrané trasy",
		download: "Stiahnuť aplikáciu",
		about: (
			<React.Fragment>
				<h2 className="pt-4 pb-4">Vysoké Tatry cestou necestou</h2>
				<p>
					Projekt je spolufinancovaný Európskou úniou z&nbsp;prostriedkov Európskeho fondu regionálneho rozvoja
					v&nbsp;rámci Programu cezhraničnej spolupráce Interreg V-A&nbsp;Poľsko-Slovensko 2014&nbsp;-&nbsp;2020.
				</p>
				<p>
					Vedúci partner projektu: Mesto Vysoké Tatry<br/>
					Partner projektu: Mesto Zakopane<br/>
					Realizácia projektu: 05/2017 – 04/2018
				</p>
				<h2 className="pt-4 pb-4">Zakopane – Mesto Vysoké Tatry – tatranské cyklotrasy</h2>
				<p>
					Projekt je spolufinancovaný Európskou úniou z&nbsp;prostriedkov Európskeho fondu regionálneho rozvoja
					v&nbsp;rámci Programu cezhraničnej spolupráce Interreg V-A&nbsp;Poľsko-Slovensko 2014&nbsp;–&nbsp;2020.
				</p>
				<p>
					Vedúci partner projektu: Mesto Zakopane<br />
					Partner projektu: Mesto Vysoké Tatry
				</p>
				<p>
					Výhradnú zodpovednosť za obsah tejto webovej stránky nesú jej autori a&nbsp;nedá sa stotožniť
					s&nbsp;oficiálnym stanoviskom Európskej únie.
				</p>
			</React.Fragment>
		),
	},
	pl: {
		description: "",
		featuredPlaces: "Polecane miejsca",
		featuredPaths: "Polecane trasy",
		download: "Pobierz aplikację",
		about: (
			<React.Fragment>
				<h2 className="pt-4 pb-4">Tatry Wysokie na przełaj</h2>
				<p>
					Projekt jest współfinansowany przez Unię Europejską z&nbsp;Europejskiego Funduszu Rozwoju Regionalnego
					w&nbsp;ramach Programu Współpracy Transgranicznej Interreg V-A&nbsp;Polska-Słowacja 2014&nbsp;-&nbsp;2020.
				</p>
				<p>
					Główny partner projektu: Miasto Wysokie Tatry<br/>
					Partner projektu: Gmina Miasto Zakopane<br/>
					Realizacja projektu: 05/2017&nbsp;-&nbsp;04/2018
				</p>
				<h2 className="pt-4 pb-4">Zakopane - Miasto Wysokie Tatry - Tatrzańskie trasy rowerowe</h2>
				<p>
					Projekt jest współfinansowany przez Unię Europejską z&nbsp;Europejskiego Funduszu Rozwoju Regionalnego
					w&nbsp;ramach Programu Współpracy Transgranicznej Interreg V-A&nbsp;Polska-Słowacja 2014&nbsp;-&nbsp;2020.
				</p>
				<p>
					Główny partner projektu: Miasto Zakopane<br/>
					Partner projektu: Miasto Wysokie Tatry<br/>
				</p>
				<p>
					Wyłączna odpowiedzialność za treść niniejszej strony internetowej spoczywa na autorach i&nbsp;nie można jej
					poprzeć oficjalnym stanowiskiem Unii Europejskiej.
				</p>
			</React.Fragment>
		),
	},
};
