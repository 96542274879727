import MapData, { MapDataLiteral } from "../../common/model/MapData";
import HttpApi from "./HttpApi";



export default class MapDataProvider
{

	private api: HttpApi;


	constructor(httpApi: HttpApi)
	{
		this.api = httpApi;
	}


	public async fetchMapData(): Promise<MapData>
	{
		const data = await this.api.get("map-data") as MapDataLiteral;
		return new MapData(data);
	}

}
