import ViewDataResolver from "../../common/model/ViewDataResolver";
import HttpApi from "./HttpApi";



export default class BrowserViewDataResolver implements ViewDataResolver
{

	private api: HttpApi;
	private cache: {[key: string]: { value: any }};


	constructor(api: HttpApi)
	{
		this.api = api;
		this.cache = {};
	}


	async resolve(keys: string[]): Promise<{[key: string]: any}>
	{
		let result = {};
		let keysToLoad = [];
		for (const key of keys) {
			if (this.cache[key] !== undefined) {
				result[key] = this.cache[key].value;
			} else {
				keysToLoad.push(key);
			}
		}

		if (keysToLoad.length > 0) {
			const loadedData = await this.api.post("resolve-view-data", keysToLoad);
			for (const key of keys) {
				this.cache[key] = { value: loadedData[key] };
				result[key] = loadedData[key];
			}
		}

		return result;
	}


	resolveFromCache(keys: string[]): {[key: string]: any} | undefined
	{
		let result = {};
		for (const key of keys) {
			if (this.cache[key] !== undefined) {
				result[key] = this.cache[key].value;
			} else {
				return undefined;
			}
		}
		return result;
	}


	addCache(data: { [key: string]: any }): void
	{
		for (const key of Object.keys(data)) {
			this.cache[key] = {value: data[key]};
		}
	}


	async sleep(millis: number): Promise<void>
	{
		return new Promise<void>(yeah => setTimeout(yeah, millis));
	}

}
