import MapData from "../../model/MapData";



export function pathBounds(mapData: MapData, pathId: number): google.maps.LatLngBounds
{
	const bounds = new google.maps.LatLngBounds();
	mapData.segments
		.filter(s => s.pathIds.includes(pathId))
		.forEach(s => s.points.forEach(p => bounds.extend(p)));
	return bounds;
}
