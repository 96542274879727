import * as React from "react";
import Fade from "reactstrap/lib/Fade";
import { GoogleMap } from 'react-google-maps';
import {animationMillis} from "../utils/constants";
import Icon from "./Icon";
import Link from "./Link";
import MapComponent from "./MapComponent";
import MapData, {
	MapDataColorLiteral,
	MapDataSegmentLiteral,
	MapDataPointLiteral,
	MapDataPathLiteral
} from "../model/MapData";
import Marker from "react-google-maps/lib/components/Marker";
import Polyline from "react-google-maps/lib/components/Polyline";
import LatLngLiteral = google.maps.LatLngLiteral;
import {pathBounds} from "../utils/helpers/pathBounds";


// must by in same as css values
const SIDE_SMALL_WIDTH = 440;
const SIDE_BIG_REST_WIDTH = 200;
const SIDE_BIG_MIN_WIDTH = 440;

const PLACE_DEFAULT_ZOOM = 15;


const Z_INDEX_LAT_RATIO = 2000;
const Z_INDEX_LAT_MIN = 100;
const Z_INDEX_LAT_MAX = 360 * Z_INDEX_LAT_RATIO + Z_INDEX_LAT_MIN;


interface MapProps
{
	full: boolean;
	googleApiReady: boolean;
	mapData: MapData | undefined;
	activePointId: number | undefined;
	activePathId: number | undefined;
	onClick: () => void;
	onPlaceClick: (point: MapDataPointLiteral) => void;
	onSegmentClick: (segment: MapDataSegmentLiteral) => void;
	lang: string;
	sideCover: "none" | "small" | "big";
	isPhone: boolean | undefined;
}



interface MapState
{
	center: google.maps.LatLngLiteral | undefined;
	zoom: number | undefined;
}



export default class Map extends React.PureComponent<MapProps, MapState>
{

	private refMap: GoogleMap | undefined;
	private icons: {[key: string]: google.maps.Icon};


	constructor(props: MapProps, context?: any)
	{
		super(props, context);

		this.state = {
			center: undefined,
			zoom: undefined,
		};
	}


	public panZoomToPlace(placeId: number) {
		const point = this.props.mapData?.pointsById[placeId];
		if (point) {
			this.panZoomTo({lat: point.lat, lng: point.lng});
		}
	}


	public panZoomToPath(pathId: number) {
		const { mapData } = this.props;
		if (mapData) {
			// todo: fitBounds withOffset
			this.refMap?.fitBounds(pathBounds(mapData, pathId));
		}
	}


	public panZoomTo(center: google.maps.LatLngLiteral, zoom: number = PLACE_DEFAULT_ZOOM)
	{
		this.setState({ zoom }, () => {
			const offset = this.evaluateCenterOffset();
			this.panToWithOffset(center, offset.x, offset.y);
		});
	}


	render()
	{
		const { full } = this.props;

		return (
			<div className={"Map" + (full ? " Map--fullscreen" : "")}>
				{this.renderMap()}
				{this.renderOverlay()}
			</div>
		);
	}


	private renderMap(): JSX.Element | null
	{
		const { mapData, googleApiReady, onPlaceClick, onSegmentClick, activePointId, activePathId } = this.props;
		const { zoom, center } = this.state;

		if (!googleApiReady || !mapData) {
			return null;
		}

		if (this.icons === undefined) {
			this.icons = initIcons();
		}

		return (
			<MapComponent
				mapRef={this.setMapRef}
				defaultOptions={{
					clickableIcons: false,
					disableDefaultUI: true,
					gestureHandling: "greedy",
					// mapTypeId: google.maps.MapTypeId.TERRAIN,
				}}
				center={center} onCenterChanged={this.handleCenterAndZoomChanged}
				zoom={zoom} onZoomChanged={this.handleCenterAndZoomChanged}
				onClick={this.handleMapClick}
			>
				<MapSegments
					segments={mapData.segments}
					colorsById={mapData.colorsById}
					pathsById={mapData.pathsById}
					activePathId={activePathId}
					zoom={zoom}
					onClick={onSegmentClick}
				/>
				<MapPoints
					points={mapData.points}
					onClick={onPlaceClick}
					activePointId={activePointId}
					icons={this.icons}
				/>
			</MapComponent>
		);
	}



	private setMapRef = (ref: GoogleMap) => this.refMap = ref;


	private renderOverlay(): JSX.Element
	{
		const { full, lang } = this.props;
		const msgs = messages[lang];

		return (
			<Fade in={!full} {...{
				baseClass: "Map__overlay",
				baseClassActive: "Map__overlay--active",
				timeout: { enter: animationMillis/2, exit: animationMillis },
				mountOnEnter: false,
				unmountOnExit: true,
				appear: false,
			}}>
				<div className="Map__jumbotron container d-flex flex-column align-items-center text-center align-items-md-start justify-content-center">
					<h1>{msgs.heading}</h1>
					<p>{msgs.claim}</p>
					<Link to="map" className="btn btn-primary">
						<Icon name="fullscreen" className="mr-2" />
						{msgs.openMap}
					</Link>
				</div>
			</Fade>
		);
	}


	private handleCenterAndZoomChanged = (): void =>
	{
		if (!this.refMap){
			return;
		}

		this.setState({
			zoom: this.refMap.getZoom(),
			center: this.refMap.getCenter().toJSON(),
		});
	}


	private handleMapClick = (): void =>
	{
		this.props.onClick.call(undefined);
	}


	componentDidUpdate(prevProps: MapProps)
	{
		// ensure refMap is set (refs are set after componentDidUpdate)
		setTimeout(() => this.componentDidUpdateAndRefsAreReady(prevProps), 0);
	}


	private componentDidUpdateAndRefsAreReady(prevProps: MapProps): void
	{
		const props = this.props;
		const isReady = !!(props.googleApiReady && props.mapData);
		const wasReady = !!(prevProps.googleApiReady && prevProps.mapData);

		if (!isReady || !this.refMap) {
			return;
		}

		if (!wasReady) {
			this.initMapView();
		} else {
			this.updateMapView(props, prevProps);
		}
	}


	private initMapView(): void
	{
		const map = this.getMapRef();
		const { mapData, activePointId, activePathId } = this.props;
		if (!mapData) throw new Error("mapData not ready");
		const activePoint = activePointId && mapData.pointsById[activePointId] || undefined;
		const activePath = activePathId && mapData.pathsById[activePathId] || undefined;
		const activeItem =
			activePoint ?	{ type: 'place', place: activePoint } as const :
			activePath ? { type: 'path', path: activePath } as const :
			undefined;

		if (!activeItem) {
			const { sw, ne } = mapData.initialViewBounds;
			const bounds = new google.maps.LatLngBounds(sw, ne);
			map.fitBounds(bounds);
			return;
		}

		const tryProjectionReady = () => {
			if (!map.getProjection()) {
				setTimeout(tryProjectionReady, 10);
				return;
			}

			if (activeItem.type === 'place') {
				const { place } = activeItem;
				this.panZoomToPlace(place.id);
			}

			if (activeItem.type === 'path') {
				const { path } = activeItem;
				this.panZoomToPath(path.id);
			}
		};

		tryProjectionReady();
	}


	private updateMapView(props: MapProps, prevProps: MapProps): void
	{
		// center active point on map, if:
		// 0. There is active point (activePoint !== undefined)

		// 1. Phone (isPhone === true):
		// - 1.1. active point has changed: (activePointIdChanged)
		//
		// 2. Desktop: (isPhone === false)
		//  - 2.1. sidebar remains big, active point has changed: (wasBigSidebar && isBigSidebar && activePointIdChanged)
		//  - 2.2. sidebar went big: (!wasBigSidebar && isBigSidebar)
		//  - 2.3. sidebar went small from big: (!wasBigSidebar && isBigSidebar)
		//  - 2.4. active point changed and not visible on viwport: (activePointIdChanged)

		// todo: handle active path changes

		const isPhone = !!props.isPhone;
		const isSmallSidebar = (props.sideCover === "small");
		const isBigSidebar = (props.sideCover === "big");
		const wasBigSidebar = (prevProps.sideCover === "big");

		const activePointId = props.activePointId;
		const activePointIdChanged = (activePointId !== prevProps.activePointId);
		const activePoint = activePointId && props.mapData && props.mapData.pointsById[activePointId];

		let setCenter = false;

		if (!activePoint) { // 0.
			return;
		} else if (isPhone) { // 1.
			setCenter = activePointIdChanged; // 1.1
		} else if (wasBigSidebar ? (isSmallSidebar || (isBigSidebar && activePointIdChanged)) : isBigSidebar) { // 2.1 - 2.3
			setCenter = true;
		} else if (activePointIdChanged) { // 2.4
			const pad = 30;
			const viewBounds = this.evaluateBounds({left: pad + SIDE_SMALL_WIDTH, right: pad, top: pad, bottom: pad});
			const latOff = (viewBounds.latMin > activePoint.lat || activePoint.lat > viewBounds.latMax);
			const lngOff = (viewBounds.lngMin > activePoint.lng || activePoint.lng > viewBounds.lngMax);
			setCenter = (latOff || lngOff);
		}

		if (setCenter) {
			const offset = this.evaluateCenterOffset();
			this.panToWithOffset({lat: activePoint.lat, lng: activePoint.lng}, offset.x, offset.y);
		}
	}


	private evaluateBounds(offset: {left: number, top: number, bottom: number, right: number}): { latMin: number, latMax: number, lngMin: number, lngMax: number }
	{
		const map = this.getMapRef();
		const bounds = map.getBounds();
		const ne = offsetLatLng(bounds.getNorthEast().toJSON(), offset.right, offset.top, map);
		const sw = offsetLatLng(bounds.getSouthWest().toJSON(), -offset.left, -offset.bottom, map);

		return {
			latMin: sw.lat,
			latMax: ne.lat,
			lngMin: sw.lng,
			lngMax: ne.lng,
		}
	}


	private evaluateCenterOffset(): { x: number, y: number }
	{
		if (!this.props.isPhone) {

			if (this.props.sideCover === "small") {
				return {x: SIDE_SMALL_WIDTH / 2, y: 0};
			}

			if (this.props.sideCover === "big") {
				const mapWidth = this.getMapRef().getDiv().clientWidth;
				const sideWidth = Math.max(SIDE_BIG_MIN_WIDTH, mapWidth - SIDE_BIG_REST_WIDTH);
				const restWidth = mapWidth - sideWidth;

				return {
					x: (mapWidth - restWidth) / 2,
					y: 0,
				}
			}
		}

		return { x: 0, y: 0 };
	}


	private getMapRef(): GoogleMap
	{
		if (!this.refMap) {
			throw new Error("Map not initialized yet.");
		}
		return this.refMap;
	}


	/**
	 * @param center point
	 * @param offsetX - the distance to move to the right, in pixels
	 * @param offsetY - the distance to move upwards, in pixels
	 */
	panToWithOffset(center: google.maps.LatLngLiteral, offsetX: number, offsetY: number): void
	{
		const map = this.getMapRef();
		const offsetCenter = offsetLatLng(center, offsetX, offsetY, map);
		map.panTo(offsetCenter);
	}

}



function initIcons(): {[key: string]: google.maps.Icon}
{
	const size = 30;
	const sizeBig = 60;
	return {
		"panorama": {
			url: '/res/marker/place.svg',
			anchor: new google.maps.Point(size * 768 / 1536, size * 1260 / 1536),
			scaledSize: new google.maps.Size(size, size),
		},
		"panoramaActive": {
			url: '/res/marker/place-selected.svg',
			anchor: new google.maps.Point(sizeBig * 768 / 1536, sizeBig * 1260 / 1536),
			scaledSize: new google.maps.Size(sizeBig, sizeBig),
		},
	}
}



function offsetLatLng(pos: LatLngLiteral, offsetX: number, offsetY: number, map: GoogleMap): LatLngLiteral
{
	const projection = map.getProjection();

	const zoom = map.getZoom();

	const point = projection.fromLatLngToPoint(new google.maps.LatLng(pos.lat, pos.lng));
	const scale = Math.pow(2, zoom);
	const scaledOffsetX = offsetX / scale;
	const scaledOffsetY = offsetY / scale;
	const pointOffset = new google.maps.Point(point.x - scaledOffsetX, point.y + scaledOffsetY);
	const posOffset = map.getProjection().fromPointToLatLng(pointOffset);

	return posOffset.toJSON();
}



const messages: {[lang: string]: {
	heading: string;
	claim: string;
	openMap: string;
}} = {
	sk: {
		heading: "Vysoké Tatry",
		claim: "Virtuálne prehliadky a\xa0turistické chodníky",
		openMap: "Preskúmať mapu",
	},
	pl: {
		heading: "Wysokie Tatry",
		claim: "Wirtualne wycieczki i\xa0szlaki turystyczne",
		openMap: "Przeglądaj mapę",
	},
};



interface MapPointProps
{
	point: MapDataPointLiteral;
	onClick: () => void;
	isActive: boolean;
	icons: any;
}



interface MapPointsProps
{
	points: MapDataPointLiteral[];
	onClick: (point: MapDataPointLiteral) => void;
	activePointId: number | undefined;
	icons: any;
}



class MapPoints extends React.PureComponent<MapPointsProps>
{

	public render()
	{
		const {points, onClick, activePointId, icons} = this.props;
		return (
			<React.Fragment>
				{points.map(point => (
					<MapPoint
						key={"point-" + point.id}
						point={point}
						onClick={this.mapOnClick(point.id)}
						isActive={point.id === activePointId}
						icons={icons}
					/>
				))}
			</React.Fragment>
		);
	}


	private cache: {[id: number]: () => void} = {};


	private mapOnClick(id: number): () => void
	{
		if (!this.cache[id]) {
			this.cache[id] = () => this.handlePointClick(id);
		}
		return this.cache[id];
	}


	private handlePointClick(id: number)
	{
		const point = this.props.points.find(point => point.id === id);
		if (point) {
			this.props.onClick(point);
		}
	}

}



class MapPoint extends React.PureComponent<MapPointProps>
{

	public render(): JSX.Element
	{
		const {point, onClick, isActive, icons} = this.props;
		const zIndex = isActive ? (Z_INDEX_LAT_MAX + 100) : (180 - point.lat) * Z_INDEX_LAT_RATIO + Z_INDEX_LAT_MIN;
		const icon = icons[isActive ? "panoramaActive" : "panorama"];

		return (
			<Marker
				icon={icon}
				position={{lat: point.lat, lng: point.lng}}
				opacity={isActive ? 1 : 0.9}
				clickable={!isActive}
				onClick={onClick}
				zIndex={zIndex}
			/>
		);
	}


}



interface MapSegmentsProps
{
	segments: MapDataSegmentLiteral[];
	colorsById: {[id: number]: MapDataColorLiteral};
	pathsById: {[id: number]: MapDataPathLiteral};
	zoom: number | undefined;
	activePathId: number | undefined;
	onClick: (segment: MapDataSegmentLiteral) => void;
}



class MapSegments extends React.PureComponent<MapSegmentsProps>
{

	public render()
	{
		const { segments, colorsById, pathsById, zoom, activePathId, onClick } = this.props;
		return (
			<React.Fragment>
				{zoom !== undefined && segments.map(segment => (
					<MapSegment
						key={"path-" + segment.id}
						segment={segment}
						colorsById={colorsById}
						pathsById={pathsById}
						activePathId={
							activePathId !== undefined
							&& segment.pathIds.includes(activePathId)
								? activePathId : undefined
						}
						onClick={onClick}
						zoom={zoom}
					/>
				))}
			</React.Fragment>
		);
	}

}



interface MapPathSegmentProps
{
	segment: MapDataSegmentLiteral;
	colorsById: {[id: number]: MapDataColorLiteral};
	pathsById: {[id: number]: MapDataPathLiteral};
	activePathId: number | undefined;
	zoom: number;
	onClick: (segment: MapDataSegmentLiteral) => void;
}



class MapSegment extends React.PureComponent<MapPathSegmentProps>
{

	public render()
	{
		const {segment, colorsById, pathsById, zoom, activePathId, onClick} = this.props;

		const paths = segment.pathIds.map(pathId => pathsById[pathId]);

		const hikeColorIds = Array.from(new Set([
			...segment.colorIds,
			...paths.map(path => path.hikeColorId).filter(id => id !== null) as number[]
		]));
		const bikeColorIds = paths.map(path => path.bikeColorId).filter(id => id !== null) as number[];
		const allColorIds = Array.from(new Set([...hikeColorIds, ...bikeColorIds]));

		let strokeWeight = 2;
		let strokeWeightOuter = 5;
		let dashLength = 5;
		let outerOpacity = 0.5;
		let bikeScale = 0.5;
		if (zoom >= 16) {
			strokeWeight = 5;
			strokeWeightOuter = 10;
			dashLength = 10;
			outerOpacity = 0.8;
			bikeScale = 0.7;
		}
		if (allColorIds.length > 0) {
			outerOpacity = 0.8;
		}
		if (zoom >= 19) {
			strokeWeightOuter = 15;
		}

		const isActive = activePathId !== undefined;

		if (isActive) {
			strokeWeight += 1;
			strokeWeightOuter += 1;
		}

		const icons: google.maps.IconSequence[] = [];

		if (allColorIds.length > 1) {
			const weight = strokeWeight;
			const length = dashLength;

			const repeat = (length * allColorIds.length) + "px";

			for (let index = 0; index < allColorIds.length; index++) {
				const colorId = allColorIds[index];
				const color = colorById(colorId);
				const opacity = colorOpacity(colorId, { hike: true, bike: true });
				icons.push({
					icon: dashIcon({ length: length - weight, weight, color, opacity }),
					offset: (length * (index + 0.5)) + "px",
					repeat,
				})
			}
		}

		for (let index = 0; index < bikeColorIds.length; index++) {
			const colorId = bikeColorIds[index];
			const color = colorById(colorId);
			const opacity = colorOpacity(colorId, { hike: false, bike: true });
			const space = bikeScale * (bikeColorIds.length > 2 ? 50 : bikeColorIds.length > 1 ? 80 : 150);
			const offset = ((index + 0.5) * space) + 'px';
			const repeat = bikeColorIds.length * space + 'px';
			const scale = bikeScale;
			icons.push(
				{ icon: circleIcon({ scale, opacity }), fixedRotation: true, offset, repeat },
				{ icon: bikeIcon({ scale, color, opacity }), fixedRotation: true, offset, repeat },
			);
		}

		return (
			<React.Fragment>
				{isActive ? (<Polyline options={{
					path: segment.points,
					geodesic: true,
					strokeColor: "#fff",
					strokeWeight: strokeWeightOuter + 10,
					strokeOpacity: 0.7,
					clickable: false,
					zIndex: 0,
				}} />) : null}

				<Polyline
					options={{
						path: segment.points,
						geodesic: true,
						strokeColor: "#fff",
						strokeWeight: strokeWeightOuter,
						strokeOpacity: isActive ? 1 : outerOpacity,
						clickable: paths.length > 0,
						zIndex: (allColorIds.length > 0 ? 2 : 1),
					}}
					onClick={() => onClick(segment)}
				/>

				<Polyline options={{
					path: segment.points,
					geodesic: true,
					strokeColor: (allColorIds.length === 1 ? colorById(allColorIds[0]) : undefined),
					strokeOpacity: (allColorIds.length === 1 ? colorOpacity(allColorIds[0], { hike: true, bike: true }) : 0),
					strokeWeight: strokeWeight,
					clickable: false,
					zIndex: 3,
					icons,
				}} />
			</React.Fragment>
		);

		function colorById(id: number): string
		{
			return '#' + colorsById[id].color;
		}

		function colorOpacity(colorId: number, options: { hike: boolean, bike: boolean }) {
			const active = activePathId !== undefined;

			if (active) {
				const activePath = pathsById[activePathId];
				const {bikeColorId, hikeColorId} = activePath ?? {bikeColorId: null, hikeColorId: null};
				const isColorActive = (options.hike && hikeColorId === colorId) || (options.bike && bikeColorId === colorId);
				if (isColorActive) {
					return 1;
				}
			}

			return 0.5;
		}

	}
}

const dashIcon = ({length, weight, color, opacity}: {length: number, weight: number, color: string, opacity: number}): MapSymbol => {
	const half = length/2;
	return ({
		path: 'M0 -' + half + ' V' + half,
		strokeWeight: weight,
		strokeColor: color,
		strokeOpacity: opacity,
		scale: 1,
	});
};

const squareIcon = ({scale, opacity}: {scale: number, opacity: number}): MapSymbol => ({
	path: "m0 0h22v22h-22z",
	fillColor: "#fff",
	fillOpacity: opacity,
	strokeWeight: 0,
	anchor: new google.maps.Point(11, 11),
	scale,
})

const bikeIcon = ({color, scale, opacity}: {color:string, scale: number, opacity: number}): MapSymbol => ({
	path: "m18.478 16.793c-0.24394 0.21626-0.43746 0.41894-0.6607 0.58066-1.2417 0.89938-2.6167 1.516-4.1275 1.7667-0.84282 0.13982-1.6958 0.1573-2.5653 0.04801-1.1399-0.14327-2.2055-0.46244-3.2108-0.98684-1.6683-0.87043-2.9613-2.1322-3.7592-3.8484-0.28437-0.61149-0.53228-1.2604-0.661-1.9187-0.16202-0.82879-0.18648-1.6746-0.063159-2.5338 0.16255-1.1324 0.54038-2.1674 1.1336-3.1284 0.72821-1.1795 1.7032-2.1194 2.8905-2.8332 0.82789-0.4977 1.714-0.87733 2.6585-1.0705 0.87455-0.17883 1.7574-0.28819 2.666-0.21708 1.0354 0.081012 2.0025 0.30458 2.9743 0.70473s1.8888 0.97297 2.7053 1.7409c-0.8694 0.88799-1.848 1.7806-2.682 2.5689-0.43544 0.43236-0.42059 0.41241-0.89998 0.055433-0.61569-0.45847-1.3236-0.7153-2.0831-0.82677-1.1087-0.16285-2.1374 0.070135-3.0684 0.67967-0.84545 0.55343-1.4799 1.3079-1.7413 2.29-0.54075 2.032 0.46379 3.9026 2.3491 4.7855 0.59724 0.27972 1.2315 0.39388 1.8712 0.38848 1.07-0.0091 2.0514-0.34265 2.8851-1.0328 0.18288-0.15152 0.28242-0.13449 0.44129 0.0192 1.0424 1.0027 1.9828 1.8944 2.9475 2.7684z",
	fillColor: color,
	fillOpacity: opacity,
	strokeWeight: 0,
	anchor: new google.maps.Point(11, 11),
	scale,
});

// https://stackoverflow.com/questions/5737975/circle-drawing-with-svgs-arc-path
const circleIcon =  ({scale, opacity}: {scale: number, opacity: number}): MapSymbol => ({
	path: 'm -11, 0 a 11,11 0 1,1 22,0 a 11,11 0 1,1 -22,0',
	fillColor: "#fff",
	fillOpacity: opacity,
	strokeWeight: 0,
	scale,
});

type MapSymbol = google.maps.IconSequence['icon'];
