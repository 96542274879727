import * as React from "react"
import Fade from "reactstrap/lib/Fade";
import {animationMillis} from "../utils/constants";



export interface ContentProps
{
	hidden: boolean;
	children: JSX.Element | string | undefined | null;
}



interface ContentState
{
	children: JSX.Element | string | undefined | null;
}



export default class ContentTemplate extends React.PureComponent<ContentProps, ContentState>
{


	constructor(props: ContentProps, context?: any) {
		super(props, context);

		this.state = {
			children: props.children,
		}
	}


	componentWillReceiveProps(nextProps: ContentProps)
	{
		if (!nextProps.hidden) {
			this.setState({children: nextProps.children});
		}
	}


	render()
	{
		const {hidden} = this.props;
		const {children} = this.state;

		return (
			<Fade in={!hidden} {...{
				baseClass: "Content",
				baseClassActive: "Content--show",
				timeout: { enter: animationMillis / 4, exit: animationMillis },
				mountOnEnter: false,
				unmountOnExit: true,
				appear: false,
			}}>
				{children}
			</Fade>
		);
	}

}
