import {MainTemplateParams} from "../components/MainTemplate";



export default interface View
{
	controller: string;
}



export interface ViewDef
{
	controller?: string;
	[param: string]: any;
}



export abstract class ViewController<V extends View>
{

	sanitize(view: Partial<V> | ViewDef, currentView: View): void
	{
		if (view.controller === undefined || view.controller === "this") {
			view.controller = currentView.controller;
		}
	}


	isResolved(view: V): boolean
	{
		const fields = Object.keys(this.getViewDataResolvingKeys(view));
		for (const field of fields) {
			if (view[field] === undefined) {
				return false;
			}
		}
		return true;
	}


	abstract equals(view: V, view2: View): boolean;


	abstract getViewDataResolvingKeys(view: V): {[key: string]: string};

	abstract getTitle(view: V): string;
	abstract getUrl(view: V): string;
	abstract render(view: V): MainTemplateParams;

}
