import "./main.scss";

import "pannellum/build/pannellum.js";
import "pannellum/build/pannellum.css";

import BrowserViewDataResolver from "./services/BrowserViewDataResolver";
import ViewControllerFactory from "../common/views/ViewControllerFactory";
import ViewResolver from "../common/model/ViewResolver";
import MapDataProvidder from "./services/MapDataProvider";
import HttpApi from "./services/HttpApi";
import GoogleApiProvider from "./services/GoogleMapsApiProvider";

import bootstrap from "./bootstrap";
import MainAppRoot, {
	MainAppParams,
	MainAppServices
} from "../common/apps/MainApp";
import MapSearch from "./services/MapSearch";



bootstrap(MainAppRoot, (params: MainAppParams): MainAppServices => {

	const api = new HttpApi();
	const viewDataResolver = new BrowserViewDataResolver(api);
	const viewController = ViewControllerFactory.create(params.config);
	const viewResolver = new ViewResolver(viewController, viewDataResolver);
	const googleApiProvider = new GoogleApiProvider().register();
	const mapDataProvider = new MapDataProvidder(api);
	const mapSearch = new MapSearch(api);

	return {
		viewController,
		viewResolver,
		googleApiProvider,
		mapDataProvider,
		mapSearch,
	};

});
