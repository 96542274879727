import * as React from "react";
import {animationMillis} from "../utils/constants";
import Fade from "reactstrap/lib/Fade";



interface SideContentProps
{
	show: boolean;
	cover: JSX.Element | string | null | undefined;
	title: JSX.Element | string | null | undefined;
	headerControls: JSX.Element | string | null | undefined;
	content: JSX.Element | string | null | undefined;
	fullscreen: boolean;
}



interface SideContentState
{
	lastShown: {
		cover: JSX.Element | string | null | undefined,
		title: JSX.Element | string | null | undefined,
		headerControls: JSX.Element | string | null | undefined,
		content: JSX.Element | string | null | undefined,
	}
}



export default class SideContent extends React.PureComponent<SideContentProps, SideContentState>
{

	constructor(props: SideContentProps, context?: any)
	{
		super(props, context);

		this.state = {
			lastShown: {
				cover: props.cover,
				title: props.title,
				headerControls: props.headerControls,
				content: props.content,
			},
		};
	}


	componentWillReceiveProps(nextProps: SideContentProps)
	{
		if (nextProps.show) {
			this.setState({
				lastShown: {
					cover: nextProps.cover,
					title: nextProps.title,
					headerControls: nextProps.headerControls,
					content: nextProps.content,
				}
			});
		}
	}



	render()
	{
		const { show, fullscreen } = this.props;
		const { cover, title, content, headerControls } = this.state.lastShown;

		return (
			<Fade in={show} {...{
				baseClass: "SideContent",
				baseClassActive: "SideContent--show",
				className: "shadow--2" + (fullscreen ? " SideContent--fullscreen" : ""),
				timeout: { enter: 0, exit: animationMillis },
				mountOnEnter: false,
				unmountOnExit: true,
				appear: false,
			}}>
				<div className="SideContent__inner">
					<div className="SideContent__cover">
						{cover}
					</div>
					<Fade in={!fullscreen} {...{
						baseClass: "",
						baseClassActive: "",
						appear: false,
						mountOnEnter: false,
						unmountOnExit: true,
						timeout: {enter: 1, exit: animationMillis },
					}}>
						<div className="SideContent__header">
							{headerControls}
							<h1 className="SideContent__title">{title}</h1>
						</div>
						<div className="SideContent__content">
							{content}
						</div>
					</Fade>
				</div>
			</Fade>
		);
	}

}
