import * as React from "react";

import BaseView, {BaseViewController} from "./BaseView";
import View, {ViewDef} from "./View";
import {MainTemplateParams} from "../components/MainTemplate";
import Panorama from "../components/Panorama";
import Link from "../components/Link";
import Icon from "../components/Icon";



export interface DetailViewData
{
	type: "place" | "path";
	id: number;
	title: string;
	slug: string;
	panorama: string;
	contentHtml: string;
}



export default interface DetailView extends BaseView
{
	controller: "detail";
	entity?: { type: "place" | "path", id: number };
	slug?: string;

	fullscreen: boolean;

	data?: DetailViewData;
}



export class DetailViewController extends BaseViewController<DetailView>
{

	private baseurlPanorama: string;


	constructor(baseUrlPanorama: string)
	{
		super();

		this.baseurlPanorama = baseUrlPanorama;
	}


	sanitize(view: Partial<DetailView> & ViewDef, currentView: View): void
	{
		super.sanitize(view, currentView);

		if (DetailViewController.isDetailView(currentView)) {
			if (view.entity === undefined && view.slug === undefined) {
				view.entity = currentView.entity;
				if (view.lang === currentView.lang) {
					view.slug = currentView.slug;
				}
			}
			if (view.fullscreen === undefined) {
				view.fullscreen = currentView.fullscreen;
			}
		}
	}


	equals(view: DetailView, view2: View): boolean
	{
		return super.equals(view, view2)
			&& DetailViewController.isDetailView(view2)
			&& view.entity?.type === view2.entity?.type
			&& view.entity?.id === view2.entity?.id
			&& view.fullscreen === view2.fullscreen;
	}


	getViewDataResolvingKeys(view: DetailView)
	{
		return {
			data: "detail:" + (view.entity !== undefined ? view.entity.type + '-' + view.entity.id : this.getSlug(view)) + ":" + view.lang,
		}
	}


	getTitle(view: DetailView): string
	{
		return this.getBaseTitle(view, view.data ? view.data.title : messages[view.lang].loading);
	}


	getSlug(view: DetailView): string | undefined
	{
		return view.data ? view.data.slug : view.slug;
	}


	getUrl(view: DetailView): string
	{
		return super.getUrl(view) +
			(this.getSlug(view) || view.entity?.type + '-' + view.entity?.id) +
			(view.fullscreen ? "/panorama" : "") // todo: localize 'panorama'
		;
	}


	render(view: DetailView): MainTemplateParams
	{
		const msgs = messages[view.lang];

		let refPanorama: Panorama;

		const setPanoramaRef = (ref: Panorama) => {refPanorama = ref};
		const requestPanoFullscreen = () => {refPanorama && refPanorama.requestFullscreen()};

		const activeEntity = {
			type: view.data ? view.data.type : view.entity?.type,
			id: view.data ? view.data.id : view.entity?.id,
		};

		return {
			...super.renderBase(view),
			status: 200,
			title: this.getTitle(view),
			description: "",
			robots: "index, follow",
			canonicalUrl: undefined,
			alternateLangUrls: {},

			logoLink: view.fullscreen ? {fullscreen: false} : "map",
			mapActivePoint: activeEntity.type === 'place' ? activeEntity.id : undefined,
			mapActivePath: activeEntity.type === 'path' ? activeEntity.id : undefined,
			fullMap: true,
			showSide: true,
			sideFullscreen: view.fullscreen,
			sideTitle: view.data ? view.data.title : msgs.loading,
			sideHeaderControls: this.renderHeaderControls(view, requestPanoFullscreen),
			sideCover: this.renderCover(view, setPanoramaRef, requestPanoFullscreen),
			sideContent: this.renderContent(view),
		};
	}


	private renderHeaderControls(view: DetailView, requestFullscreen: () => void): JSX.Element
	{
		return (
			<div className="SideContent__header-controls">
				<Link
					to={view.fullscreen ? {fullscreen: false} : "map"}
					className="SideContent__header-control d-none d-sm-inline-block"
				>
					<Icon name="close" />
				</Link>

				<Link
					to="map"
					className="SideContent__header-control d-inline-block d-sm-none">
					<Icon name="close" />
				</Link>
			</div>
		);
	}


	private renderCover(view: DetailView, refPanorama: (ref: Panorama) => void, requestFullscreen: () => void): JSX.Element
	{
		if (!view.data) {
			return <div />;
		}

		const baseUrlPanorama = this.baseurlPanorama;
		const panoramaDir = view.data.panorama;

		const onFullscreenClick = (evt: React.MouseEvent<HTMLAnchorElement>) => {
			evt.preventDefault();
			requestFullscreen();
		};

		return (
			<React.Fragment>
				<Panorama
					ref={refPanorama}
					config={{
						config: baseUrlPanorama + panoramaDir + "/config.json",
						title: null,
						author: null,
						autoLoad: true,
						// autoRotate: -5,
						// autoRotateInactivityDelay: 5000,
						compass: false,
						showZoomCtrl: false,
						showControls: false,
					}}
					// onViewChange={(view) => {
					// 	console.log("View change: ", view);
					// }}
				/>
				<div className="SideContent__cover-controls shadow--1">
					{!view.fullscreen && (
						<Link
							to={{fullscreen: true}}
							className="SideContent__cover-control"
						>
							<Icon name="fullscreen" />
						</Link>
					)}
					{view.fullscreen && (
						<Link
							to={{fullscreen: false}}
							className="SideContent__cover-control"
						>
							<Icon name="keyboard_arrow_left" className="d-none d-sm-inline-block" />
							<Icon name="fullscreen_exit" className="d-sm-none" />
						</Link>
					)}
					{view.fullscreen && (
						<Link
							to={{fullscreen: true}}
							className="SideContent__cover-control d-none d-sm-inline-block"
							onClick={onFullscreenClick}
						><Icon name="fullscreen" /></Link>
					)}
				</div>
			</React.Fragment>
		);
	}


	private renderContent(view: DetailView): JSX.Element
	{
		if (!view.data) {
			return (<div />);
		}
		return (<div dangerouslySetInnerHTML={{__html: view.data.contentHtml}} />);
	}


	static isDetailView(view: View): view is DetailView
	{
		return (view.controller === "detail");
	}

}



const messages: {[lang: string]: {
	loading: string;
}} = {
	sk: {
		loading: "Nahrávam...",
	},
	pl: {
		loading: "Nahrávam...",
	},
};
