import * as React from "react";



interface IconProps extends React.HTMLProps<HTMLElement>
{
	name: string;
}



export default class Icon extends React.PureComponent<IconProps>
{

	render()
	{
		const {name, className, ...rest} = this.props;

		return (
			<i
				className={"Icon material-icons" + (className ? " " + className : "") }
				{ ...rest}
			>{name}</i>
		);
	}

}