import * as React from "react";
import {
	resolveConfig as resolvePannellumConfig,
	Pannellum, PannellumConfig, PannellumResolvedConfig, PannellumViewer, PanoramaView
} from "../utils/pannelum";


declare const pannellum: Pannellum;



interface PanoramaProps
{
	config: PannellumConfig;
	onViewChange?: (view: PanoramaView) => void;
}



interface PanoramaState
{
}



export default class Panorama extends React.PureComponent<PanoramaProps, PanoramaState>
{

	private viewPoolingInterval: number | undefined = undefined;
	private refContainer: HTMLDivElement;
	private refViewer: PannellumViewer | undefined;

	private initCounter: number = 0;
	private currentConfig: PannellumConfig | undefined;


	async componentDidMount()
	{
		this.initViewer(this.props.config);
	}


	componentWillReceiveProps(nextProps: PanoramaProps)
	{
		this.updateViewer(nextProps.config);
	}


	componentWillUnmount()
	{
		this.destroyViewer();
	}


	render()
	{
		return (
			<div
				ref={(ref: HTMLDivElement) => this.refContainer = ref}
				className="Panorama"
			/>
		);
	}


	requestFullscreen(): void
	{
		this.refViewer && this.refViewer.toggleFullscreen();
	}


	private initViewer(config: PannellumConfig): void
	{
		this.initCounter++;
		this.currentConfig = config;

		const initNum = this.initCounter;
		resolvePannellumConfig(config).then((resolvedConfig) => {
			if (initNum !== this.initCounter) {
				return;
			}

			this.refViewer = pannellum.viewer(this.refContainer, resolvedConfig);
			this.startViewPooling();
		});
	}


	private updateViewer(config: PannellumConfig): void
	{
		if (JSON.stringify(config) === JSON.stringify(this.currentConfig)) {
			return;
		}
		this.destroyViewer();
		this.initViewer(config);
	}


	private destroyViewer(): void
	{
		this.initCounter++;

		this.stopViewPooling();
		if (this.refViewer) {
			this.refViewer.destroy();
			this.refViewer = undefined;
		}
	}


	private startViewPooling(): void
	{
		const onViewChange = this.props.onViewChange;
		const poolingInterval = 1000/30;
		let lastView: PanoramaView | undefined = undefined;
		let lastWidth: number | undefined = undefined;
		let lastHeight: number | undefined = undefined;

		this.viewPoolingInterval = window.setInterval(() => {
			if (!this.refViewer) {
				return;
			}

			const width = this.refContainer.offsetWidth;
			const height = this.refContainer.offsetHeight;

			if (width !== lastWidth || height !== lastHeight) {
				this.refViewer.resize();
				lastWidth = width;
				lastHeight = height;
			}

			const view: PanoramaView = {
				pitch: this.refViewer.getPitch(),
				yaw: this.refViewer.getYaw(),
				hfov: this.refViewer.getHfov(),
			};

			const viewChanged = (!lastView || lastView.pitch !== view.pitch || lastView.yaw !== view.yaw || lastView.hfov !== view.hfov);
			lastView = view;

			if (viewChanged && onViewChange) {
				onViewChange(view);
			}

		}, poolingInterval);
	}


	private stopViewPooling(): void
	{
		if (this.viewPoolingInterval !== undefined) {
			window.clearInterval(this.viewPoolingInterval);
			this.viewPoolingInterval = undefined;
		}
	}

}
