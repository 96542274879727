export function byKey<T>(list: T[], key: string): {[key: number]: T}
{
	const result = {};
	for (const item of list) {
		result[item[key]] = item;
	}
	return result;
}


/**
 * https://stackoverflow.com/a/37511463
 *
 * @param {string} string
 * @returns {string}
 */
export function toSearchString(string: string): string
{
	return string
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.toLocaleLowerCase();
}



export function objectMap<T_FROM,T_TO>(input: {[key: string]: T_FROM}, mapFn: (item: T_FROM) => T_TO): {[key: string]: T_TO}
{
	const result = {};
	Object.keys(input).forEach((key) => result[key] = mapFn(input[key]));
	return result;
}



export function randomString(length: number, chars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"): string
{
	let result = "";

	for (let i = 0; i < length; i++) {
		result += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return result;
}
