import View, {ViewController, ViewDef} from "./View";
import {MainTemplateParams} from "../components/MainTemplate";



export default interface BaseView extends View
{
	lang: string;
}



export abstract class BaseViewController<V extends BaseView> extends ViewController<BaseView>
{

	private defaultLang: string = 'sk'; // todo: inject;


	equals(view: BaseView, view2: View): boolean
	{
		return BaseViewController.isBaseView(view2) && (view.lang === view2.lang);
	}


	sanitize(view: ViewDef, currentView: View): void
	{
		super.sanitize(view, currentView);

		if (view.lang === undefined) {
			view.lang = BaseViewController.isBaseView(currentView) ? currentView.lang : this.defaultLang;
		}
	}


	protected getBaseTitle(view: V, extra?: string)
	{
		return (extra !== undefined ? extra + " - " : "") + messages[view.lang].title;
	}


	abstract getTitle(view: V): string;


	getUrl(view: V): string
	{
		let result = '/';

		if (view.lang !== this.defaultLang) {
			result += view.lang + '/';
		}

		return result;
	}


	protected renderBase(view: BaseView): { lang: string }
	{
		return {
			lang: view.lang,
		};
	}


	abstract render(view: V): MainTemplateParams;


	static isBaseView(view: View): view is BaseView
	{
		return (view as BaseView).lang !== undefined;
	}

}



const messages: {[lang: string]: {
	title: string;
}} = {
	sk: {
		title: "TatryVirtualne.sk",
	},
	pl: {
		title: "TatryVirtualne.sk (pl)",
	},
};
