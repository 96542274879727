import * as React from "react";
import {hydrate} from "react-dom";
import metadataRenderer from "./services/metadataRenderer";



interface Params
{
	[key: string]: any;
}



export default function(rootComponent: any, servicesFactory: (params: Params) => {[name: string]: any}): void
{

	const init = () => {
		const rootElement = document.getElementById("app-root") as HTMLDivElement;
		const params = JSON.parse(rootElement.dataset["params"] as string);
		const services = servicesFactory(params);
		hydrate(React.createElement(rootComponent, { ...params, ...services, metadataRenderer}), rootElement);
	};

	if (document.readyState !== "loading") {
		init();
	} else {
		document.addEventListener("DOMContentLoaded", init);
	}

};
