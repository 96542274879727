import * as React from "react";
import {object as ObjectPropType} from "prop-types";
import {ViewDef} from "../views/View";
import MainApp from "../apps/MainApp";



interface LinkProps extends React.HTMLProps<HTMLAnchorElement>
{
	to: string | ViewDef | null | undefined;
	forceReload?: boolean;
}



export default class Link extends React.PureComponent<LinkProps>
{

	context: { app: MainApp };


	static contextTypes = {
		app: ObjectPropType,
	};


	render()
	{
		const {to, children, onClick, forceReload, ...rest} = this.props;

		const href = to ? this.context.app.createHref(to) : "javascript:;";

		return (
			<a href={href} onClick={(e) => this.handleClick(e)} {...rest}>{children}</a>
		);
	}


	private handleClick(evt: React.MouseEvent<HTMLAnchorElement>): void
	{
		if (!this.props.to) {
			evt.preventDefault();
			return;
		}

		if (this.props.onClick !== undefined) {
			this.props.onClick.call(undefined, evt);
			if (evt.isDefaultPrevented()) {
				return;
			}
		}

		if (!evt.shiftKey && !evt.ctrlKey && !this.props.forceReload) {
			evt.preventDefault();
			this.context.app.go(this.props.to);
		}
	}

}
