import {byKey} from "../utils/helpers";



export interface MapDataLiteral
{
	initialViewBounds: MapDataLatLngBoundsLiteral,
	points: MapDataPointLiteral[];
	colors: MapDataColorLiteral[];
	segments: MapDataSegmentLiteral[];
	paths: MapDataPathLiteral[];
}



export default class MapData implements MapDataLiteral
{

	public initialViewBounds: MapDataLatLngBoundsLiteral;
	public points: MapDataPointLiteral[];
	public pointsById: {[id: number]: MapDataPointLiteral};
	public colors: MapDataColorLiteral[];
	public colorsById: {[id: number]: MapDataColorLiteral};
	public segments: MapDataSegmentLiteral[];
	public segmentsById: {[id: number]: MapDataSegmentLiteral};
	public paths: MapDataPathLiteral[]
	public pathsById: {[id: number]: MapDataPathLiteral};


	constructor(data: MapDataLiteral)
	{
		this.initialViewBounds = data.initialViewBounds;
		this.points = data.points;
		this.colors = data.colors;
		this.segments = data.segments;
		this.paths = data.paths;

		this.pointsById = byKey(this.points, 'id');
		this.colorsById = byKey(this.colors, 'id');
		this.segmentsById = byKey(this.segments, 'id');
		this.pathsById = byKey(this.paths, 'id');
	}

}



export interface MapDataLatLngLiteral
{
	lat: number;
	lng: number;
}



export interface MapDataLatLngBoundsLiteral
{
	ne: MapDataLatLngLiteral;
	sw: MapDataLatLngLiteral;
}



export interface MapDataPointLiteral extends MapDataLatLngLiteral
{
	id: number;
	// alt: number;
}



export interface MapDataColorLiteral
{
	id: number;
	color: string;
}



export interface MapDataSegmentLiteral
{
	id: number;
	pathIds: number[];
	colorIds: number[];
	points: MapDataLatLngLiteral[];
}


export interface MapDataPathLiteral
{
	id: number;
	hikeColorId: number | null;
	bikeColorId: number | null;
}
