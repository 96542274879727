import {MapViewController} from "./MapView";
import {HomeViewController} from "./HomeView";
import {DetailViewController} from "./DetailView";
import CompositeViewController from "./CompositeViewController";
import {MainAppConfig} from "../apps/MainApp";



export default class ViewControllerFactory
{

	public static create(config: MainAppConfig): CompositeViewController
	{
		const { baseUrlPanorama: panoramaBaseUrl, appleItunesAppUrl, googlePlayAppId } = config;

		return new CompositeViewController({
			"home": new HomeViewController(panoramaBaseUrl, appleItunesAppUrl, googlePlayAppId),
			"map": new MapViewController(),
			"detail": new DetailViewController(panoramaBaseUrl),
		});
	}

}
